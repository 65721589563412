.button,
.btn {
  border-radius: 8px 0 8px 0;
  border-corner-shape: bevel;
  &.button-primary {
    border-radius: 8px 0 8px 0;
    border-corner-shape: bevel;
  }
}

.star-icon,
.icon-button {
  &.active {
    color: $ui-primary-color;
  }
}

// Fix the modal colors
.actions-modal,
.boost-modal,
.confirmation-modal,
.mute-modal,
.block-modal,
.report-modal,
.embed-modal {
  background: darken($ui-base-color, 6%);
  color: $primary-text-color;

  .status.light {
     .status__content {
      color: $primary-text-color;
    }
  }
}

.boost-modal__action-bar,
.favourite-modal__action-bar,
.confirmation-modal__action-bar,
.mute-modal__action-bar,
.block-modal__action-bar {
  background: darken($ui-base-color, 6%);
  color: $dark-text-color;
}

.error-modal,
.onboarding-modal,
.onboarding-modal__paginator,
.error-modal__footer {
  background: darken($ui-base-color, 6%);
  color: $primary-text-color;

  .onboarding-modal__nav,
  .error-modal__nav {
    &:hover,
    &:focus,
    &:active {
      background-color: $action-button-color;
    }

    &.onboarding-modal__done,
    &.onboarding-modal__next {
      color: $primary-text-color;

      &:hover,
      &:focus,
      &:active {
        color: $primary-text-color;
      }
    }
  }
}

.compose-form__autosuggest-wrapper,
.poll__option input[type='text'],
.compose-form .spoiler-input__input,
.compose-form__poll-wrapper select,
.search__input,
.setting-text,
.report-dialog-modal__textarea,
.audio-player {
  border: 1px solid lighten($ui-base-color, 8%);
}

// Change the colors used in compose-form
.compose-form {
//   .compose-form__modifiers {
//     .compose-form__upload__actions .icon-button,
//     .compose-form__upload__warning .icon-button {
//       color: lighten($white, 7%);
// 
//       &:active,
//       &:focus,
//       &:hover {
//         color: $white;
//       }
//     }
//   }

  .compose-form__modifiers,
  .compose-form__buttons-wrapper, {
    background: lighten($ui-base-color, 4%);
  }

  .poll__option input[type='text'],
  .autosuggest-input,
  .spoiler-input__input,
  .privacy-dropdown__dropdown,
  .privacy-dropdown__option,
  .privacy-dropdown__option__content,
  .emoji-mart-scroll,
  .emoji-picker-dropdown__menu,
  .language-dropdown__dropdown__results,
  .language-dropdown__dropdown__results__item,
  .autosuggest-textarea__suggestions,
  .autosuggest-textarea__textarea {
    background: lighten($ui-base-color, 4%) !important;
    color: $darker-text-color !important;
    border-radius: 0 0 0 0 !important;
    &::placeholder {
      color: lighten($darker-text-color, 4%) !important;
    }
  }

  .autosuggest-textarea__suggestions__item {
    &:hover,
    &:focus,
    &:active,
    &.selected {
      background: lighten($ui-base-color, 4%);
    }
  }
}

